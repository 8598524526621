import React from "react";
import "./central_styles.css";

type CentralGoalProps = {
  onClick: any;
};

const CentralGoal: React.FC<CentralGoalProps> = ({ onClick }) => {
  return (
    <div className="centralGoalWrapper">
      <div className="centralGoalContent">
        <p className="centralGoalFirstText">OUR CENTRAL GOAL</p>

        <h1 className="centralGoalSecondText">
          From Dating To Building Lives With Spowse
        </h1>

        <p className="centralGoalThirdText">
          We’re a platform built for African singles with features aimed to spark
          real conversations and foster genuine connections based on interests,
          compatibility, location and cultural background.{" "}
        </p>

        <button className="button centralGoalButton" onClick={onClick}>
          Join our waitlist
        </button>
      </div>
    </div>
  );
};
export default CentralGoal;
