import React from "react";
import "./the_game_changer_styles.css";
import { theGameChangerDetails } from "../../logic/home_controller";
import AnimationWidget from "../../../../core/commons/animation_widget";

type TheGameChangerProps = {};

const TheGameChanger: React.FC<TheGameChangerProps> = () => {
  return (
    <div className="theGameChangerWrapper">
      <div className="theGameChangerContent">
        <AnimationWidget styles={true}>
          <p className="theGameChangerFirstText">THE GAMECHANGER</p>
        </AnimationWidget>
        <AnimationWidget styles={true}>
          <h1 className="theGameChangerSecondText">
            We’re Your Sidekicks In Your Quest For True Love
          </h1>
        </AnimationWidget>
        <AnimationWidget styles={true}>
          <p className="theGameChangerThirdText">
            Get a fresh start on purposeful dating with advanced features
            designed to bring love your way.
          </p>
        </AnimationWidget>

        <div className="theGameChangerGridContainer">
          {theGameChangerDetails.map((details, index) => {
            return (
              <div
                className="theGameChangerGridCard"
                key={index}
                style={{ background: `${details.backgroundColor}` }}
              >
                <img
                  src={details.image}
                  alt="img"
                  className="theGameChangerGridImage"
                />

                <h1 className="theGameChangerGridTitle">{details.title}</h1>

                <p className="theGameChangerGridTextContent">
                  {details.content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default TheGameChanger;
