import React from "react";
import "./trybe_styles.css";
import AnimationWidget from "../../../../core/commons/animation_widget";
import { Link } from "react-router-dom";

type TrybeProps = {};

const Trybe: React.FC<TrybeProps> = () => {
  return (
    <div className="trybeWrapper">
      <div className="trybeContent">
        <div className="trybeMiddleContent">
          <AnimationWidget styles={true}>
            <h3 className="trybeFirstText">FIND TRYBE, FIND LOVE</h3>
          </AnimationWidget>
          <AnimationWidget styles={true}>
            <h1 className="trybeSecondText">
              Your One-Stop Shop For Modern Dating Tips
              {/* <span className="trybeSpanText">expert advice</span> */}
            </h1>
          </AnimationWidget>
          <AnimationWidget styles={true}>
            <p className="trybeThirdText">
              Date smarter and love stronger while building meaningful
              relationships with Trybe.
            </p>
          </AnimationWidget>
          <AnimationWidget styles={true}>
            <Link to="/blogs">
              <button className="button trybeButtons">View blog</button>
            </Link>
          </AnimationWidget>
        </div>
      </div>
    </div>
  );
};
export default Trybe;
