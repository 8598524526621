export const theGameChangerDetails = [
  {
    backgroundColor: "#CEF4DB",
    image: "/assets/icons/Finding online date.svg",
    title: "Curated Connections",
    content:
      "Spowse uses your interests and cultural background to curate compatible matches, so you can focus on building meaningful connections.",
  },
  {
    backgroundColor: "#FFE499",
    image: "/assets/icons/Dating app.svg",
    title: "Slides And Sparks",
    content:
      "Unlock a new way to connect as Spowse makes finding connections fun and interactive. Get ready to slide your way up to love.",
  },
  {
    backgroundColor: "#A7BEB4",
    image: "/assets/icons/Couple doing conversation on dating app.svg",
    title: "Love In Your Orbit",
    content:
      "Get ready to spark unexpected connections with amazing people nearby with Spowse's unique feature. Love might be closer than you slide.",
  },
  {
    backgroundColor: "#82E3A1",
    image: "/assets/icons/Searching for girlfriend.svg",
    title: "Beyond The Profiles",
    content:
      "Spowse makes connections exciting with this feature as it gives you the opportunity to unveil more and explore both sides, finding your ideal partner.",
  },
];

export const faqs = [
  {
    topic: "What is Spowse?",
    contents: [
      "Spowse is a dating app designed to the African community. We believe that dating should be safe, fun and inclusive such that people can be their authentic selves, and express their interests and values without any fear of judgement.",
      "We strive to foster a community of respectful and genuine individuals who are open to exploring new connections and building long-lasting relationships with like-minded people.",
    ],
  },
  {
    topic: "How does Spowse work?",
    contents: [
      "Spowse's algorithm learns your preferences based on your values, interests and advanced filter selections. Since we believe in quality over quantity, we ensure that you have the best shot at finding a compatible partner.",
      "You will be matched with only a few users at a time. This boosts your ability to focus on each match more closely and increase your chances of making a genuine connection.",
    ],
  },
  {
    topic: "What makes Spowse different from other platforms",
    contents: [
      "We are purpose-driven. Unlike other dating apps, we don't encourage mindless dating. We want to see you find love, nurture it and build a future that lasts with your partner.",
    ],
  },

  {
    topic: "Is Spowse safe and secure?",
    contents: [
      "Yes! We place a high priority on your safety so we've put strict measures in place to ensure that you're protected from fraudsters, catfishing, virtual abuse and unplesant offline experiences.",
    ],
  },
  {
    topic: "How can I learn more about Spowse",
    contents: [
      "Join our waitlist, We'll send you behind-the-scenes gossip and keep you updated about our plans, upcoming events and collaborations.",
    ],
  },
];
