import React, { useEffect, useState } from "react";
import { AppIcons } from "../../core/utils/app_icons";
import "./header_styles.css";
import { Link, useNavigate } from "react-router-dom";

type HeaderProps = {
  backgroundColor?: any;
  showButton?: boolean | true;
};

const Header: React.FC<HeaderProps> = ({ showButton, backgroundColor }) => {
  const [scrolling, setScrolling] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 30;

      setScrolling(scrollTop > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className="headerWrapper"
      style={{
        background:
          backgroundColor ??
          (scrolling
            ? "linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(239, 247, 244, 1), rgba(16, 40, 29, 0.23), rgba(255, 255, 255, 0.31))"
            : "transparent"),
        paddingTop: !showButton ? "1.3rem" : "1rem",
        paddingBottom: !showButton ? "1.3rem" : "1rem",
      }}
    >
      <div className="headerContent">
        <img
          src={AppIcons.spowseLogo}
          alt="logo"
          className="spowseLogo"
          onClick={() => {
            navigate("/", { replace: true });
          }}
        />
        {showButton && (
          <Link to="/blogs">
            <button className="button headerButton">Blog</button>
          </Link>
        )}
      </div>
    </div>
  );
};
export default Header;
