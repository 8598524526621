import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

type AnimationWidgetProps = {
  children: React.ReactNode;
  styles?: any;
  delay?: any;
};

const AnimationWidget: React.FC<AnimationWidgetProps> = ({
  children,
  styles,
  delay,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <div
      ref={ref}
      style={
        styles
          ? {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
          : { width: "auto" }
      }
    >
      <motion.div
        style={
          styles
            ? {
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }
            : { width: "auto" }
        }
        variants={{
          hidden: { opacity: 0, y: 70 },
          visible: { opacity: 1, y: 0, x: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1, delay: delay || 0 }}
      >
        {children}
      </motion.div>
    </div>
  );
};
export default AnimationWidget;


export const AnimationWidget2: React.FC<AnimationWidgetProps> = ({
  children,
  styles,
  delay,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <div
      ref={ref}
      style={
        styles
          ? {
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }
          : { width: "auto" }
      }
    >
      <motion.div
        style={
          styles
            ? {
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }
            : { width: "auto" }
        }
        variants={{
          hidden: { opacity: 0, y: 70 },
          visible: { opacity: 1, y: 0, x: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1, delay: delay || 0 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export const AnimationWidget3: React.FC<AnimationWidgetProps> = ({
  children,
  styles,
  delay,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <div
      ref={ref}
      style={
        styles
          ? {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
          : {}
      }
    >
      <motion.div
        style={
          styles
            ? {
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }
            : {}
        }
        variants={{
          hidden: { opacity: 0, y: 70 },
          visible: { opacity: 1, y: 0, x: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1, delay: delay || 0 }}
      >
        {children}
      </motion.div>
    </div>
  );
};
