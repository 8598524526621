import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { blogs } from "../blog/logic/blog_controller";
import { Helmet } from "react-helmet-async";
import "./blog_details_styles.css";
import Header from "../header/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type BlogDetailsProps = {};

const BlogDetails: React.FC<BlogDetailsProps> = () => {
  const { id } = useParams();

  const blog = blogs.find((e) => e.id === id);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="blogDetailsWrapper">
      <Helmet>
        <title>{blog?.title}</title>
        <meta name="description" content={blog?.contents[0]} />
        <meta charSet="utf-8" />
        <link rel="canonical" href={`/blog/${id}`} />
      </Helmet>
      <Header showButton={false} />
      <div className="blogDetailsContent">
        <h1 className="blogTitle">{blog?.title}</h1>
        {blog?.image2 !== "" ? (
          <div className="blogImageAndContentContainer">
            {blog?.contents.map((content, index) => {
              if (index >= 0 && index <= 1) {
                return (
                  <p key={index} className="blogContent">
                    {content}
                  </p>
                );
              }
              return null;
            })}
            <LazyLoadImage
              src={blog?.image2}
              alt="img"
              effect="blur"
              className="blogImage"
            />
            {blog?.contents.map((content, index) => {
              if (index >= 2) {
                return (
                  <p key={index} className="blogContent">
                    {content}
                  </p>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <div className="blogImageAndContentContainer">
            {blog?.contents.map((content, index) => {
              return (
                <p key={index} className="blogContent">
                  {content}
                </p>
              );
            })}
            <LazyLoadImage
              src={blog?.image}
              alt="img"
              effect="blur"
              className="blogImage"
            />{" "}
          </div>
        )}
        {blog?.subContents.map((subContent, index) => {
          const nextSubContentImage = blog?.subContents[index + 1]?.image;
          return (
            <div
              style={{
                paddingBottom: nextSubContentImage
                  ? "0px"
                  : windowWidth <= 600
                  ? "30px"
                  : "60px",
              }}
              key={index}
              className="subContentContainer"
            >
              {subContent.image != null && (
                <LazyLoadImage
                  src={subContent.image}
                  alt="img"
                  effect="blur"
                  className="subContentImage"
                />
              )}
              {subContent.supTopic !== "" && (
                <h1 className="subContentSupTopic">{subContent.supTopic}</h1>
              )}
              <p
                className="subContentTopic"
                style={{
                  paddingTop: subContent.topic === "The Giver" ? "2rem" : "0",
                }}
              >
                {subContent.topic}
              </p>
              {subContent.contents.map((content, index) => {
                return (
                  <p key={index} className="blogContent">
                    {content}
                  </p>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default BlogDetails;
