import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./presentations/home/Home";
import Footer from "./presentations/footer/Footer";
import Blog from "./presentations/blog/Blog";
import BlogDetails from "./presentations/blog_details/BlogDetails";
import ScrollToTopOnNavigate from "./core/commons/scroll_to_top";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <ScrollToTopOnNavigate />
      <div className="App">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
