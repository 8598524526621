import React, { useEffect, useState } from "react";
import "./hero_styles.css";
import { AppImages } from "../../../../core/utils/app_images";
import { motion } from "framer-motion";
import TextLoop from "./widgets/carousel";
import { AppIcons } from "../../../../core/utils/app_icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ReactLoading from "react-loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type HeroProps = {
  id: any;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Hero: React.FC<HeroProps> = ({ id }) => {
  const [isUp, setIsUp] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsUp((prevIsUp: any) => !prevIsUp);
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const springProps = {
    translateY: isUp ? -50 : 0,
    transition: {
      duration: 3,
      ease: "easeInOut",
    },
  };

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await axios
          .post("https://admin-dev.spowse.io/waitlist", values)
          .then((e) => {
            if (e.data["success"] === true) {
              setIsLoading(false);
              formik.resetForm();
              toast.success(e.data["message"], {
                position: "bottom-center",
                autoClose: 3000,
                theme: "dark",
              });
            } else {
              setIsLoading(false);
              toast.error(e.data["message"], {
                position: "bottom-center",
                autoClose: 3000,
                theme: "dark",
              });
            }
          });
      } catch (e) {
        setIsLoading(false);
        toast.error("Email already exist!", {
          position: "bottom-center",
          autoClose: 3000,
          theme: "dark",
        });
        return;
      }
    },
  });

  return (
    <div className="heroWrapper">
      <div className="heroContent" id={id}>
        <div className="heroLeftSection">
          <TextLoop />
          <h1 className="heroSectionBigText">Ready To Write Your Love Story</h1>
          <p className="heroSectionSmallText">
            From Lagos to Nairobi, Accra to Harare and Johannesburg , discover
            genuine connections in a community of African singles just like you.
            Save your spot now and get notified when we launch.
          </p>
          <form className="inputButtonContainer" onSubmit={formik.handleSubmit}>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="Enter your email address"
            />{" "}
            {isMobileView ? (
              <button className="checkContainer2" type="submit">
                {isLoading ? (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color="white"
                    height={isMobileView ? 20 : 32}
                    width={isMobileView ? 20 : 32}
                  />
                ) : (
                  <img src={AppIcons.slantArrow} alt="img" className="check1" />
                )}
              </button>
            ) : (
              <button className="button heroLeftSectionButton" type="submit">
                {" "}
                {isLoading ? (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color="white"
                    height={isMobileView ? 20 : 32}
                    width={isMobileView ? 20 : 32}
                  />
                ) : (
                  <p style={{ fontFamily: "matter-medium" }}> Join waitlist</p>
                )}
              </button>
            )}
          </form>
          {formik.touched.email && formik.errors.email ? (
            <div
              style={{
                color: "red",
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                padding: "10px",
              }}
            >
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <motion.div className="heroRightSection" animate={springProps}>
          <LazyLoadImage
            effect="blur"
            src={AppImages.couples}
            alt="img"
            className="heroRightImage"
          />
        </motion.div>
      </div>
    </div>
  );
};
export default Hero;
