import React, { useEffect, useState } from "react";
import "./footer_styles.css";
import { AppIcons } from "../../core/utils/app_icons";
import { IoMdCheckmark } from "react-icons/io";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ReactLoading from "react-loading";

type FooterProps = {};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Footer: React.FC<FooterProps> = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://admin-dev.spowse.io/waitlist",
          values
        );
        if (response.status <= 201) {
          setIsLoading(false);
          formik.resetForm();
          toast.success(response.data["message"], {
            position: "bottom-center",
            autoClose: 3000,
            theme: "dark",
          });
        } else {
          setIsLoading(false);
          toast.error(response.data["message"], {
            position: "bottom-center",
            autoClose: 3000,
            theme: "dark",
          });
        }
      } catch (e) {
        setIsLoading(false);
        toast.error("Email already exist!", {
          position: "bottom-center",
          autoClose: 3000,
          theme: "dark",
        });
        return;
      }
    },
  });

  return (
    <div className="footerWrapper">
      <div className="footerContent">
        <h3>JOIN OUR WAITLIST</h3>
        <h1>Get exclusive access to Spowse’s premium features</h1>
        <p>
          Be an early user and connect with other{" "}
          <span className="footerSpanText">African singles</span> on the
          platform.
          {/* singles</span> on the platform. */}
        </p>
        <form className="inputButtonContainer1" onSubmit={formik.handleSubmit}>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="Enter your email address"
          />{" "}
          {isMobileView ? (
            <button className="checkContainer" type="submit">
              {isLoading ? (
                <ReactLoading
                  type={"spinningBubbles"}
                  color="white"
                  height={isMobileView ? 20 : 32}
                  width={isMobileView ? 20 : 32}
                />
              ) : (
                <IoMdCheckmark className="check" />
              )}
            </button>
          ) : (
            <button className="button footer-button" type="submit">
              {" "}
              {isLoading ? (
                <ReactLoading
                  type={"spinningBubbles"}
                  color="white"
                  height={isMobileView ? 20 : 32}
                  width={isMobileView ? 20 : 32}
                />
              ) : (
                <p style={{ fontFamily: "matter-medium" }}>Get early access</p>
              )}
            </button>
          )}
        </form>
        {formik.touched.email && formik.errors.email ? (
          <div style={{ color: "red" }}>{formik.errors.email}</div>
        ) : null}
        <div className="iconRow">
          <a
            className="iconContainer"
            href="https://twitter.com/spowseofficial?t=qxMT9pfvi8KUs-TSxplv3g&s=09"
          >
            <img src={AppIcons.twitter} alt="icon" />
          </a>
          <a
            className="iconContainer"
            href="https://www.facebook.com/spowse.io?mibextid=ZbWKwL"
          >
            <img src={AppIcons.facebook} alt="icon" />
          </a>

          <a
            className="iconContainer"
            href="https://www.linkedin.com/company/spowse/"
          >
            <img src={AppIcons.linkedIn} alt="icon" />
          </a>
          <a
            className="iconContainer"
            href="https://instagram.com/spowseofficial?igshid=MzRlODBiNWFlZA==6:16
"
          >
            <img src={AppIcons.instagram} alt="icon" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
