import React from "react";
import "./home_styles.css";
import Hero from "./widgets/hero/Hero";
import CentralGoal from "./widgets/central_goal/CentralGoal";
import TheGameChanger from "./widgets/the_game_changer/TheGameChanger";
import Trybe from "./widgets/trybe/Trybe";
import Faq from "./widgets/faq/Faq";
import { Helmet } from "react-helmet-async";
import Header from "../header/Header";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  const scrollToComponent = (componentId: string) => {
    const component = document.getElementById(componentId);
    if (component) {
      component.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className="homeWrapper">
      <Helmet>
        <title>Spowse</title>
        <meta
          name="description"
          content="Find your perfect match on Spowse - the premier dating platform where meaningful connections blossom. Explore profiles of singles near you, discover compatible matches, and embark on a journey to find love and companionship. Join Spowse today to start your romantic adventure!"
        />
        <meta charSet="utf-8" />
        <link rel="canonical" href="/" />
      </Helmet>
      <Header showButton={true} />
      <Hero id={"joinWaitList"} />
      <CentralGoal onClick={() => scrollToComponent("joinWaitList")} />
      <TheGameChanger />
      <Trybe />
      <Faq />
    </div>
  );
};
export default Home;
