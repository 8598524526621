import React from "react";
import "./faq_styles.css";
import { faqs } from "../../logic/home_controller";
import FaqCard from "./widgets/FaqCard";

type FaqProps = {};

const Faq: React.FC<FaqProps> = () => {
  return (
    <div className="faqWrapper">
      <div className="faqContent">
        <h3 className="faqFirstText">FAQ</h3>
        <h1 className="faqSecondText">Frequently asked questions</h1>
        <p className="faqThirdText">
          Need more clarity about Spowse? Here are a few questions we often get.
        </p>
        <div className="faqsSection">
          {faqs.map((faq, index) => {
            return (
              <FaqCard topic={faq.topic} contents={faq.contents} key={index} />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Faq;
