import React from "react";
import "./widgets_styles.css";

interface Props {}

const TextLoop: React.FC<Props> = () => {
  return (
    <div className="carousel-container">
      <h1 className="for">For</h1>
      <div className="animatedContainer">
        <h1 className="scrollingText">African Singles</h1>
        <h1 className="scrollingText">Àwọn ẹni Àfrikà</h1>
        <h1 className="scrollingText">Afro-majaha</h1>
        <h1 className="scrollingText">Vanhu vari vega</h1>
        <h1 className="scrollingText">Wapenzi</h1>
        <h1 className="scrollingText">Afo no Afrika</h1>
      </div>
    </div>
  );
};

export default TextLoop;
