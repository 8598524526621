import React from "react";
import "./blog_styles.css";
import { blogs } from "./logic/blog_controller";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Header from "../header/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type BlogProps = {};

const Blog: React.FC<BlogProps> = () => {
  return (
    <div className="blogWrapper">
      <Helmet>
        <title>Blogs - Spowse</title>
        <meta
          name="description"
          content="Discover insightful articles and engaging content on the Spowse blog. From dating tips and relationship advice to love stories and lifestyle trends, our blog covers a wide range of topics to enrich your dating journey. Stay informed, inspired, and entertained with our expertly crafted blog posts. Dive into the world of dating and relationships with Spowse!"
        />
        <meta charSet="utf-8" />
        <link rel="canonical" href={`/blogs`} />
      </Helmet>
      <Header showButton={false} backgroundColor={"white"} />
      {/* hero section */}
      <div className="blogHeroContent">
        <div className="blogHeroChild">
          <div className="blogHeroChilds">
            <h1 className="heroBlogTitle">
              5 Reasons Why You Should Consider Online Dating
            </h1>
            <p className="heroBlogContent">
              Online dating has gained more popularity over the years but not
              many people have fully embraced the idea for several reasons.
              While some.
            </p>
            <Link to={`/blog/${blogs[6].id}`}>
              <button className="button heroBlogButton">Read More</button>
            </Link>
          </div>
        </div>
      </div>

      {/* blog section */}
      <div className="mainBlogContainer">
        {blogs.map((blog, index) => {
          // Skip the last blog
          if (index === blogs.length - 1) return null;

          return (
            <div className="mainBlogContent" key={index}>
              <LazyLoadImage
                src={blog.image}
                alt=""
                effect="blur"
                style={{
                  borderRadius: index === 6 ? "30px" : "0",
                  height: "auto",
                  width: "100%"
                }}
              />
              <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
              <p
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 3,
                  lineClamp: 3,
                  maxHeight: "3.6em",
                }}
              >
                {blog.contents[0]}
              </p>
              <p className="mainBlogRead">{blog.read}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Blog;
