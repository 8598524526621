import React, { useState } from "react";
import "./widgets_styles.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type FaqCardProps = {
  topic: string;
  contents: any;
};

const FaqCard: React.FC<FaqCardProps> = ({ topic, contents }) => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent((prevShowContent) => !prevShowContent);
  };

  return (
    <div className="faqCardWrapper">
      <div
        style={{
          borderBottom: showContent
            ? "1px solid var(--white)"
            : "1px solid var(--borderColor)",
        }}
        onClick={toggleContent}
      >
        <h1>{topic}</h1>
        {showContent ? (
          <IoIosArrowUp className="faqArrow" />
        ) : (
          <IoIosArrowDown className="faqArrow" />
        )}
      </div>
      <div
        style={{
          maxHeight: showContent ? "1000px" : "0px",
          borderBottom: showContent
            ? "1px solid var(--borderColor)"
            : "1px solid var(--white)",
          paddingBottom: showContent ? "1.5rem" : "0px",
        }}
      >
        {contents.map((content: any, index: any) => {
          return (
            <p
              className="k"
              key={index}
              style={{ marginBottom: contents.length === 1 ? "0" : "2rem" }}
            >
              {content}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default FaqCard;
